@import '../../styles/scss/colors/colors';
@import '../../styles/scss/font/font';

@font-face {
    font-family: "Open Sans Semibold";
    src: url("../../assets/fonts/OpenSans/OpenSans-Semibold.ttf") format('truetype')
    }

@font-face {
    font-family: "Open Sans Regular";
    src: url("../../assets/fonts/OpenSans/OpenSans-Regular.ttf") format('truetype')
    }

.login-page-container {
    background-image: url('../../assets/images/login_bckgr.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat; 
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
}

.login-form-container {
    min-width: 535px;
    height: 600px;
    align-self: center;
    background-color: $white;
    border-radius: 3px;
}

.login-form-inner {
    display: flex;
    justify-content: center;
    font-family: $projectFontFamily;
    height: 100%;
}

.login-form-fields-container {
    align-self: center;
    width: 82%;
    margin-top: 10%; 
    display: flex;
    flex-direction: column;
    height: 90%;
}

.sign-in-label {
    font-size: 30px;
    color: $gray;
}

.login-action-form {
    display: flex;
    flex-direction: column; 
    font-family: Open Sans Regular, Arial, sans-serif;
    height: 100%;
}

.login-error-notification-container {
    display: flex;
    justify-content: space-between;
    background-color: $red;
    height: 5%;
    border-radius: 4px;
    padding: 1% 2% 1% 2%;
}

.login-error-info {
    width: 96%;
}

.error-notification-icon {
    width: 10%;
    color: $white;
}

.error-text {
    margin-left: 2%;
    color: $white;
}

.error-notification-dismiss-icon {
    margin-left: 2%;
    color: #555;
    opacity: 0.3;
    width: 4%;
    cursor: pointer;
}

.forgot-password-link {
    font-size: 18px;
    color: $blue;
    text-align: center; 
    margin-top: 10%;
}

.login-button {
    background-color: $blue;
    color: #ddd;
    height: 55px;
    font-size: 18px; 
    border: none;
    border-radius: 6px;
    margin-top: 4%;
    cursor: pointer;
}

.login-email-label,
.login-password-label {
    font-size: 16px;
    color: #5391AE;
}

.login-email-input-container,
.login-password-input-container {
    padding: 16px;
    background-color: #F8F7F8;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #D8DDE1;
    margin-top: 1%;
}

.login-email-input-container span,
.login-password-input-container span {
    width: 10%;
    color: #AAA9AA;
}

.login-email-input-container input,
.login-password-input-container input {
    background-color: #F8F7F8;
    border: none;
    width: 87%;
    margin-left: 3%;
}

.login-email-input-container input::placeholder,
.login-password-input-container input::placeholder {
    color: #888
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #444
}
  
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 2px;
}

.checkbox-container:hover input ~ .checkbox-checkmark {
    background-color: #ccc;
}

.checkbox-container input:checked ~ .checkbox-checkmark {
    background-color: $blue;
}

.checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.checkbox-container input:checked ~ .checkbox-checkmark:after {
    display: block;
}
  
.checkbox-container .checkbox-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.pull-down-4 {
    margin-top: 4%;
}

.pull-down-5 {
    margin-top: 5%;
}

.pull-down-6 {
    margin-top: 6%;
}
