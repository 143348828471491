.appBar {
  background-image: url('../../assets/images/top_bckgr.png');
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row !important;
  padding: 10px;
}

.logout {
  color: white;
}
