.graph-container {
  display: flex;
  flex-grow: 1;
}

.graph-container p {
  padding: 5px 2px;
  margin: 0;
  textalign: center;
}

.customNode {
  width: 322px;
  height: 150px;
  border: 2px solid black;
  padding: 5px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: white;
}

.simple-floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.simple-floatingedges .react-flow__handle {
  width: 8px;
  height: 8px;
  background-color: #bbb;
}

.simple-floatingedges .react-flow__handle-top {
  top: -15px;
}

.simple-floatingedges .react-flow__handle-bottom {
  bottom: -15px;
}

.simple-floatingedges .react-flow__handle-left {
  left: -15px;
}

.simple-floatingedges .react-flow__handle-right {
  right: -15px;
}

.react-flow__handle {
  opacity: 0;
}
